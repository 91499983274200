import React from "react";
import "./styles.scss";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        © {new Date().getFullYear()} Samespace Inc.
      </div>
    </footer>
  );
}
