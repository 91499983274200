import React, { useEffect, useState } from "react";
import { bubbleData } from "../../utils/bubbleData";
import tinycolor from "tinycolor2";
import "./styles.scss";
import { motion } from "framer-motion";

export default function BubbleView({ widgetColor }) {
  const [randomColors, setRandomColors] = useState([]);

  useEffect(() => {
    const splitComplementColors = tinycolor(widgetColor).triad();
    const tempArray = splitComplementColors.map(function (t) {
      return t.toHexString();
    });
    setRandomColors(tempArray);
  }, [widgetColor]);

  return (
    <section className="bubble-view">
      <div className="bubble-view__wrapper">
        {bubbleData.map((bubble, index) => {
          return (
            <motion.div
              key={bubble.id}
              style={{
                width: bubble.width,
                height: bubble.height,
                backgroundColor: randomColors
                  ? index % 2 === 0
                    ? randomColors[0]
                    : randomColors[1]
                  : "#0057ff",
                left: bubble.left,
                top: bubble.top,
                opacity: bubble.depth ? 1 : 0.3,
                zIndex: bubble.depth ? bubble.depth : 1,
              }}
              className={`bubble`}
            >
              {bubble.showImage === true ? (
                <motion.img
                  initial={{ scale: 1.6 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.8, easing: "easeInOut" }}
                  src={require(`../../assets/${bubble.id}.png`)}
                  alt=""
                />
              ) : null}
            </motion.div>
          );
        })}
      </div>
    </section>
  );
}
