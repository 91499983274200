import { useEffect, useState } from "react";
import axios from "axios";

import BubbleView from "./components/BubbleView";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import "../src/styles/main.scss";

function App() {
  const [widgetConfig, setWidgetConfig] = useState({});

  useEffect(() => {
    const url = window.location.pathname.split("/");

    const clusterId = url[2];
    const apiKey = url[3];

    if (clusterId && apiKey) {
      const widgetUrl = `https://${clusterId}.samespace.com`;
      window.samespaceAsyncInit = function () {
        window.samespace.init({
          apiKey: apiKey,
          apiBase: widgetUrl,
        });
      };

      axios
        .post(`${widgetUrl}/widget/authenticate/`, {
          url: window.location.href,
          key: apiKey,
        })
        .then((res) => {
          setWidgetConfig(res.data.config);
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <script
          src="https://widget.samespace.com/embed.js"
          id="samespace_widget_script"
          async
          defer
        ></script>
      </Helmet>

      <main className="app-view">
        {widgetConfig && (
          <BubbleView
            widgetColor={
              widgetConfig.widgetColor ? widgetConfig.widgetColor : "#0057ff"
            }
          />
        )}
        <Footer />
      </main>
    </>
  );
}

export default App;
