export const bubbleData = [
  {
    id: 1,
    width: 128,
    height: 128,
    left: "49%",
    top: "52%",
    depth: 3,
    showImage: true,
  },
  {
    id: 2,
    width: 102,
    height: 102,
    left: "59%",
    top: "52%",
    depth: 2,
    showImage: true,
  },
  {
    id: 3,
    width: 96,
    height: 96,
    left: "67%",
    top: "52%",
    depth: 2,
    showImage: true,
  },
  {
    id: 4,
    width: 84,
    height: 84,
    left: "74.5%",
    top: "52%",
    depth: 2,
    showImage: true,
  },
  {
    id: 5,
    width: 64,
    height: 64,
    left: "80.5%",
    top: "51.4%",
    depth: 2,
    showImage: true,
  },
  {
    id: 6,
    width: 94,
    height: 94,
    left: "39.6%",
    top: "51%",
    depth: 2,
    showImage: true,
  },
  {
    id: 7,
    width: 106,
    height: 106,
    left: "31.5%",
    top: "52%",
    depth: 2,
    showImage: true,
  },
  {
    id: 8,
    width: 84,
    height: 84,
    left: "23.6%",
    top: "52.4%",
    depth: 2,
    showImage: true,
  },
  {
    id: 9,
    width: 67,
    height: 67,
    left: "17.4%",
    top: "52.6%",
    depth: 2,
    showImage: true,
  },
  {
    id: 10,
    width: 92,
    height: 92,
    left: "47%",
    top: "65%",
    depth: 2,
    showImage: true,
  },
  {
    id: 11,
    width: 96,
    height: 96,
    left: "58%",
    top: "63.5%",
    depth: 2,
    showImage: true,
  },
  {
    id: 12,
    width: 82,
    height: 82,
    left: "72%",
    top: "60%",
    depth: 2,
    showImage: true,
  },
  {
    id: 13,
    width: 100,
    height: 100,
    left: "72%",
    top: "42.5%",
    depth: 2,
    showImage: true,
  },
  {
    id: 14,
    width: 64,
    height: 64,
    left: "36.5%",
    top: "59.2%",
    depth: 2,
    showImage: true,
  },
  {
    id: 15,
    width: 106,
    height: 106,
    left: "41.3%",
    top: "40.3%",
    depth: 3,
    showImage: true,
  },
  {
    id: 25,
    width: 55,
    height: 55,
    left: "35.5%",
    top: "44.5%",
    showImage: false,
  },
  {
    id: 17,
    width: 80,
    height: 80,
    left: "55.5%",
    top: "43%",
    depth: 2,
    showImage: true,
  },
  {
    id: 18,
    width: 82,
    height: 82,
    left: "49.5%",
    top: "40%",
    depth: 2,
    showImage: true,
  },
  {
    id: 19,
    width: 62,
    height: 62,
    left: "60.8%",
    top: "38%",
    depth: 2,
    showImage: true,
  },
  {
    id: 20,
    width: 63,
    height: 63,
    left: "65%",
    top: "44%",
    depth: 2,
    showImage: true,
  },
  {
    id: 21,
    width: 88,
    height: 88,
    left: "35.5%",
    top: "37%",
    depth: 2,
    showImage: true,
  },
  {
    id: 22,
    width: 62,
    height: 62,
    left: "31.7%",
    top: "42%",
    showImage: false,
  },
  {
    id: 23,
    width: 44,
    height: 44,
    left: "28.4%",
    top: "46.2%",
    showImage: false,
  },
  {
    id: 24,
    width: 28,
    height: 28,
    left: "27.8%",
    top: "40.3%",
    showImage: false,
  },
  {
    id: 16,
    width: 48,
    height: 48,
    left: "24.5%",
    top: "43.3%",
    depth: 2,
    showImage: true,
  },
  {
    id: 26,
    width: 14,
    height: 14,
    left: "25.5%",
    top: "39%",
    showImage: false,
  },
  {
    id: 27,
    width: 14,
    height: 14,
    left: "31.7%",
    top: "32.5%",
    showImage: false,
  },
  {
    id: 28,
    width: 34,
    height: 34,
    left: "20.7%",
    top: "46%",
    showImage: false,
  },
  {
    id: 29,
    width: 20,
    height: 20,
    left: "17.4%",
    top: "47%",
    showImage: false,
  },
  {
    id: 30,
    width: 32,
    height: 32,
    left: "13.2%",
    top: "53%",
    showImage: false,
  },
  {
    id: 31,
    width: 35,
    height: 35,
    left: "17%",
    top: "59.2%",
    showImage: false,
  },
  {
    id: 32,
    width: 15,
    height: 15,
    left: "20%",
    top: "58.2%",
    showImage: false,
  },
  {
    id: 33,
    width: 15,
    height: 15,
    left: "22%",
    top: "65.2%",
    showImage: false,
  },
  {
    id: 34,
    width: 70,
    height: 70,
    left: "24.2%",
    top: "59.5%",
    showImage: false,
  },
  {
    id: 35,
    width: 32,
    height: 32,
    left: "28.2%",
    top: "58.2%",
    showImage: false,
  },
  {
    id: 36,
    width: 76,
    height: 76,
    left: "32.5%",
    top: "60.4%",
    showImage: false,
  },
  {
    id: 37,
    width: 20,
    height: 20,
    left: "28.7%",
    top: "61.5%",
    showImage: false,
  },
  {
    id: 38,
    width: 32,
    height: 32,
    left: "36.2%",
    top: "67.4%",
    showImage: false,
  },
  {
    id: 39,
    width: 17,
    height: 17,
    left: "39%",
    top: "68.1%",
    showImage: false,
  },
  {
    id: 40,
    width: 34,
    height: 34,
    left: "41.2%",
    top: "69%",
    showImage: false,
  },
  {
    id: 41,
    width: 102,
    height: 102,
    left: "39.6%",
    top: "60%",
    showImage: false,
  },
  {
    id: 42,
    width: 52,
    height: 52,
    left: "44.1%",
    top: "58.4%",
    showImage: false,
  },
  {
    id: 43,
    width: 54,
    height: 54,
    left: "44.1%",
    top: "58.4%",
    showImage: false,
  },
  {
    id: 44,
    width: 54,
    height: 54,
    left: "46.1%",
    top: "33.1%",
    showImage: false,
  },
  {
    id: 45,
    width: 16,
    height: 16,
    left: "43.2%",
    top: "33.1%",
    showImage: false,
  },

  {
    id: 46,
    width: 32,
    height: 32,
    left: "43.2%",
    top: "48.7%",
    showImage: false,
  },

  {
    id: 47,
    width: 40,
    height: 40,
    left: "45.2%",
    top: "43.7%",
    showImage: false,
  },

  {
    id: 48,
    width: 24,
    height: 24,
    left: "49.9%",
    top: "34.7%",
    showImage: false,
  },
  {
    id: 49,
    width: 14,
    height: 14,
    left: "53.6%",
    top: "38.2%",
    showImage: false,
  },
  {
    id: 50,
    width: 26,
    height: 26,
    left: "52%",
    top: "46.2%",
    showImage: false,
  },
  {
    id: 51,
    width: 40,
    height: 40,
    left: "55%",
    top: "53%",
    showImage: false,
  },
  {
    id: 52,
    width: 32,
    height: 32,
    left: "54%",
    top: "58.3%",
    showImage: false,
  },
  {
    id: 53,
    width: 60,
    height: 60,
    left: "52%",
    top: "63.7%",
    showImage: false,
  },
  {
    id: 54,
    width: 60,
    height: 60,
    left: "63.4%",
    top: "59.7%",
    showImage: false,
  },
  {
    id: 55,
    width: 32,
    height: 32,
    left: "63.2%",
    top: "65.2%",
    showImage: false,
  },
  {
    id: 56,
    width: 52,
    height: 52,
    left: "60.6%",
    top: "45.5%",
    showImage: false,
  },
  {
    id: 57,
    width: 40,
    height: 40,
    left: "56.6%",
    top: "36.5%",
    showImage: false,
  },
  {
    id: 58,
    width: 78,
    height: 78,
    left: "65.6%",
    top: "39.5%",
    showImage: false,
  },
  {
    id: 59,
    width: 40,
    height: 40,
    left: "68.6%",
    top: "35.4%",
    showImage: false,
  },
  {
    id: 60,
    width: 14,
    height: 14,
    left: "66%",
    top: "33.6%",
    showImage: false,
  },
  {
    id: 61,
    width: 58,
    height: 58,
    left: "67.3%",
    top: "64.6%",
    showImage: false,
  },
  {
    id: 62,
    width: 14,
    height: 14,
    left: "70.7%",
    top: "66.2%",
    showImage: false,
  },
  {
    id: 63,
    width: 14,
    height: 14,
    left: "76.3%",
    top: "58.4%",
    showImage: false,
  },
  {
    id: 64,
    width: 32,
    height: 32,
    left: "80.6%",
    top: "57.4%",
    showImage: false,
  },
  {
    id: 65,
    width: 28,
    height: 28,
    left: "80.6%",
    top: "46.2%",
    showImage: false,
  },
  {
    id: 66,
    width: 60,
    height: 60,
    left: "77.6%",
    top: "40.2%",
    showImage: false,
  },
  {
    id: 67,
    width: 16,
    height: 16,
    left: "76%",
    top: "36.7%",
    showImage: false,
  },
  {
    id: 68,
    width: 48,
    height: 48,
    left: "85%",
    top: "50.7%",
    showImage: false,
  },
  {
    id: 69,
    width: 16,
    height: 16,
    left: "87.7%",
    top: "49.7%",
    showImage: false,
  },
];
